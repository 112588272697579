$sm: "767px";
$md: "1024px";
$lg: "1280px";
$xl: "1920px";

$FigginsSansV3: "Figgins Sans V3";
$WilliamRegularA: "William Regular A";

$black: #000000;
$white: #ffffff;

$gray100: #e6e4e4;
$gray250: #807c7c;
$gray300: #333333;
$gray400: #1a1717;

$gold200: #edd4a6;
$gold300: #d0ba92;

$purple100: #d4caff;

$red100: #ffb5c0;
