@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./variables";

@font-face {
  font-family: "Figgins Sans V3";
  src: url("/fonts/FigginsSansV3.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Figgins Sans V3 Bold";
  src: url("/fonts/FigginsSansV3-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "William Regular A";
  src: url("/fonts/WilliamRegularA.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "William Regular A";
  src: url("/fonts/WilliamRegularA-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  background-color: $gray400;
  color: $white;
  font-family: $FigginsSansV3;
  line-height: 1.1;
}

div {
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease;
}
